import React from "react";
import { Helmet } from "react-helmet";
import "../scss/App.scss";
import Logo from "../assets/Disco/logo.png";
import BackgroundImage from "../assets/Disco/lendingBackgroundDisco.jpg";
import gps from '../assets/icons/gps.png';
import gps1 from '../assets/icons/gps.svg';
import ticket from '../assets/icons/ticket.png';
import time from '../assets/icons/time.png';

import BuyButton from "../components/BuyButton/BuyButton";

import img1 from "../assets/Disco/images/img1.jpg";
import img2 from "../assets/Disco/images/img2.jpg";
import img3 from "../assets/Disco/images/img3.jpg";
import img4 from "../assets/Disco/images/img4.jpg";
import img5 from "../assets/Disco/images/img5.jpg";
import img6 from "../assets/Disco/images/img6.jpg";

// import video1 from '../assets/Disco/video1.MOV';
// import video2 from '../assets/Disco/video2.MOV';

import { motion } from "framer-motion";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";

// настройки анимации элементов
const Animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

function Disco80() {
  const dispatch = useDispatch();
  dispatch(incrementByAmount("Старый Оскол"));
  // const [eventData, setEventData] = React.useState(false); // Создаем переменную состояния "eventData" и функцию для ее обновления "setEventData"
  // const { id } = useParams(); // Получаем параметр "id" из URL

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`http://localhost:1337/api/events/${id}?populate=*`); // Отправляем GET-запрос на сервер для получения данных
  //       const jsonData = await response.json(); // Преобразуем ответ в формат JSON
  //       setEventData(jsonData.data); // Обновляем состояние "eventData" с помощью полученных данных
  //     } catch (error) {
  //       console.error('Ошибка при получении данных:', error); // В случае ошибки выводим сообщение об ошибке в консоль
  //     }
  //   };

  //   fetchData(); // Вызываем функцию fetchData при каждом рендере компонента или изменении параметра "id"
  // }, [id]);

  return (
    <>
      <Helmet>
        <title>Купить билеты на музыкальное шоу «ДИСКО 70-х, 80-х, 90–х и 2000 – х»</title>
        <meta
          name="description"
          content="Купить билеты на концерты Старый Оскол! Уникальное музыкальное шоу «ДИСКО 70-х, 80-х, 90–х и 2000 – х» 16 марта.
          Удобные схемы залов, электронные билеты.
          Легко найти интересующий вас концерт, выбрать место в зале и оформить заказ всего в несколько кликов.
          Наша платформа обеспечивает удобный и безопасный способ покупки билетов онлайн. Электронные билеты cразу после оплаты."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Купить билеты на музыкальное шоу «ДИСКО 70-х, 80-х, 90–х и 2000 – х»"></meta>
        <meta property="og:url" content="https://билетов.рус/Disco80" />
        <meta property="og:description" content="Удивительные ретро хиты, которые популярны и сегодня, прозвучат в новых авторских аранжировках для оркестра и ритм секции." />
        <meta property="og:image" content={`https://билетов.рус${BackgroundImage}`}></meta>

      </Helmet>
      <Header /> {/* Компонент Header */}
      <div className="main">
        <img
          className="background__image"
          src={BackgroundImage}
          alt="Фоновая картинка мероприятия"
        />
        <img className="main__logo" src={Logo} alt="Логотип мероприятия" />
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="textblock"
        >
          <motion.p
            variants={Animation}
            custom={1}
            className="textblock__title"
          >
            «ДИСКО 70-х, 80-х, 90–х и 2000 – х»
          </motion.p>
          <motion.p variants={Animation} custom={1} className="textblock__desc">
              Оркестр «RCO» представляет уникальную концертную программу «ДИСКО
              70-х, 80-х, 90–х и 2000 – х»<br /><br />
              Удивительные ретро хиты, которые популярны и сегодня, прозвучат в
              новых авторских аранжировках для оркестра и ритм секции. <br /><br />
              Эмоциональная и полная ностальгии программа в исполнении оркестра,
              по новому раскроет танцевальную энергию ретро дискотеки и
              подчеркнет удивительную мелодичность лирических композиций. <br /><br />
              «ДИСКО 70-х, 80-х, 90–х и 2000 – х» - это настоящий музыкальный
              подарок для всех, кто желает ощутить атмосферу неповторимых
              дискотек прошлых десятилетий.
          </motion.p>
        </motion.section>
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="iconblock"
        >
          <motion.div
            variants={Animation}
            custom={1}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={gps} />
            <h3 className="iconblock__item__title">«ЦМИ»</h3>
            <p className="iconblock__item__p">ул. Ленина д.20</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={2}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={time} />
            <h3 className="iconblock__item__title">19 ИЮНЯ</h3>
            <p className="iconblock__item__p">19:00 / 20:20</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={3}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={ticket} />
            <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
            <p className="iconblock__item__p">1500-2300 ₽</p>
          </motion.div>
        </motion.section>
        <BuyButton
          link={
            "https://билетов.рус/wp/tc-events/simfo-show-disco-80x-19-06/"
          }
        />
        <span className="concertProg">
          <span className="concertProgName">
            <br /><br /> Концертная программа
              <p className="vozrast">Возрастной рейтинг: <span className="vozrast6">6+</span></p>
          </span>
        </span>
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="textblock"
        >
          <motion.p variants={Animation} custom={1} className="textblock__desc">
            Мираж - Музыка нас связала <br />
            Bonny M - Sunny / Mamma Mia / Rasputin <br />
            Модерн Токинг - Попурри <br />
            Ю. Шатунов - Белые розы / Седая ночь <br />
            Земляне - Трава у дома <br />
            ВИА «Синяя птица» - Там где клен шумит <br />
            Музыка из к\ф «Профессионал» - Chi Mai <br />
            А. Герман - Когда цвели сады <br />
            ВИА «Лейся песня» - Синий Иней <br />
            М. Минков - Не отрекаются любя<br />
            Музыка из к\ф «Гардемарины, вперед!» - Дороги любви <br />
            Музыка из к\ф «История любви» - Love story <br />
            Поющие гитары - Люди встречаются <br />
            Русская народная песня - Ой, то не вечер<br />
          </motion.p>
        </motion.section>
        <BuyButton
          link={
            "https://билетов.рус/wp/tc-events/simfo-show-disco-80x-19-06/"
          }
        />

        {/* <video src={video1} className="video" controls="controls"/>
        <video src={video2} className="video" controls="controls"/> */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          class="grid-container"
        >
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img1}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img2}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img3}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img4}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img5}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img6}
          />
        </motion.section>
        <BuyButton link="https://билетов.рус/wp/tc-events/simfo-show-disco-80x-19-06/" />{" "}
        {/* Компонент BuyButton */}
        <p className="footerText">
          Организатор мероприятия: <br /> ИП Пастухов Константин Геннадьевич <br />
          ИНН 312800384146 <br />
          ОГРНИП 304312815600139<br />
          Юридический адрес:<br />
          305018 г. Старый Оскол, м-н. Молодогвардеец, д.16, оф. 89.
        </p>
      </div>
      <Footer /> {/* Компонент Footer */}
    </>
  );
}

export default Disco80;
